@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-SemiBold.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "DM Sans";
  src: url("assets/fonts/DM_Sans/DMSans-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "DM Sans";
  src: url("assets/fonts/DM_Sans/DMSans-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "DM Sans";
  src: url("assets/fonts/DM_Sans/DMSans-Medium.ttf");
  font-weight: 500;
}

/* @font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-ExtraBold.ttf");
  font-weight: bolder;
} */

html,
body {
  padding: 0;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;  */
  scroll-behavior: smooth;
}
.pricing-anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
  /* height: 10px; */
}
.pointer {
  cursor: pointer;
}

a {
  color: rgba(0, 133, 255, 0.85);
  /* text-decoration: none; */
}
.link {
  color: rgba(0, 133, 255, 0.85);
  font-weight: 700;
  cursor: pointer !important;
}
a.disabled {
  pointer-events: none;
  cursor: default;
}
.uppercase {
  text-transform: uppercase;
}
.title-case{
  text-transform: capitalize;
}
img {
  pointer-events: none;
}
* {
  box-sizing: border-box;
  font-family: "Poppins" !important;
}

.ld-session * {
  font-family: "DM Sans" !important;
}

.max-width {
  max-width: 1140px;
  margin: 0 auto;
}

.pbutton {
  background-color: #0085ff !important;
}
.sbutton {
  background-color: #faa004 !important;
}

.pbutton,
.sbutton {
  border-radius: 20px !important;
  padding: 10px 40px !important;
}

.sqbutton:hover,
.pbutton:hover,
.sbutton:hover {
  transform: scale(1.1);
  transition: all 0.3s;
}

.img {
  width: 100%;
  height: auto;
}
/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  /* src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2'); */
  src: url(../assets/material-icons.woff2) format("woff2");
}

.material-icons {
  font-family: "Material Icons" !important;
  font-weight: normal !important;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  font-feature-settings: "liga";
}

.mui-icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  -moz-user-select: none;
  user-select: none;
}
.emoji {
  font-size: 1.4em;
}
.emoji-large {
  font-size: 2em;
}

* {
  box-sizing: border-box;
}
.container {
  height: calc(100vh - 80px);
}
.w100 {
  width: 100%;
}
.white {
  color: #fff;
}
.h100 {
  height: 100%;
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #1f313d;
}
.roboto {
  font-family: "Times New Roman", Times, serif!important;
}
.center {
  text-align: center;
}
.justify {
  text-align: justify;
}
.bold {
  font-weight: bold !important;
}
.bold-100 {
  font-weight: 100 !important;
}
.bold-400 {
  font-weight: 400 !important;
}
.bold-500 {
  font-weight: 500 !important;
}
.m-auto {
  margin: 0 auto !important;
}
.mtb5 {
  margin: 5px 0 !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mtb10 {
  margin: 10px 0 !important;
}
.ml20 {
  margin-left: 20px !important;
}
.mtb20 {
  margin: 20px 0 !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mb50 {
  margin-bottom: 50px !important;
}
.mtb40 {
  margin: 40px 0 !important;
}
.mlr10 {
  margin: 0 10px !important;
}
.mlr5 {
  margin: 0 5px !important;
}
.p10 {
  padding: 10px;
}
.ptb5 {
  padding: 5px 0;
}
.ptb10 {
  padding: 10px 0;
}
.plr10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.ptb50 {
  padding: 50px 0;
}
.ptb100 {
  padding: 100px 0;
}
.sectionGap {
  padding: 20px;
}
.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text-decoration-none,
.text-decoration-none a {
  text-decoration: none;
  color: inherit;
}
.primary-color {
  color: #e151ab;
}
.secondary-color {
  color: rgba(0, 133, 255, 0.85);
}
.image {
  max-width: 100%;
  display: block;
  height: auto;
  margin: 0 auto;
}
.relative {
  position: relative;
}
.touch {
  -webkit-overflow-scrolling: touch;
}
.width-max-content {
  width: max-content;
}
.strike-primary {
  /* font-size: 30px;
  line-height: 40px;
  font-weight: 500; */
  margin: 0 10px;
  text-align: center;
  position: relative;
  /* // textDecoration: 'line-through', */
}
.strike-primary::after {
  position: absolute;
  top: 20%;
  left: -5px;
  content: "";
  background: url("https://res.cloudinary.com/dttau3gcw/image/upload/v1621438732/Group_ziygqh.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: left center;
  width: 110%;
  height: 20px;
  display: block;
}

.underline-primary,
.underline-secondary {
  /* font-size: 30px;
  line-height: 40px;
  font-weight: 500; */
  margin: 0 10px;
  text-align: center;
  position: relative;
  /* // textDecoration: 'line-through', */
}

.underline-primary::after,
.underline-secondary::after {
  position: absolute;
  top: 85%;
  left: -5px;
  content: "";
  width: 110%;
  height: 20px;
  display: block;
}

.underline-secondary::after {
  background: url("https://res.cloudinary.com/dttau3gcw/image/upload/v1624100248/Group_3_fmq9pa.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: left center;
}

.underline-primary::after {
  background: url("https://res.cloudinary.com/dttau3gcw/image/upload/v1623844484/Group_2_guniyt.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 15px;
  background-position: left center;
}

.slick-dots li button:before {
  color: #fde2b4;
  font-size: 16px;
  opacity: 1;
}
.slick-dots li.slick-active button:before {
  color: #faa004;
  font-size: 16px;
  opacity: 1;
}
.slick-next:before,
.slick-prev:before {
  /* color: black;
  font-family: 'Material Icons'; */
}
.body2 {
  font-size: 16px;
  line-height: 26px;
}

.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #e151ab;
  color: #e151ab;
  box-shadow: 9984px 0 0 0 #e151ab, 9999px 0 0 0 #e151ab, 10014px 0 0 0 #e151ab;
  animation: dotTyping 1.5s infinite linear;
}

.loading {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-fullpage {
  height: 100vh;
  max-height: 100%;
}

.loading .loader {
  position: absolute;
  transform: translateX(-50%);
  top: 50%;
  left: 50%;
}

.loading-fullpage .loader {
  transform: translateX(-50%) scale(4);
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 #e151ab, 9999px 0 0 0 #e151ab,
      10014px 0 0 0 #e151ab;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 #e151ab, 9999px 0 0 0 #e151ab,
      10014px 0 0 0 #e151ab;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 #e151ab, 9999px 0 0 0 #e151ab,
      10014px 0 0 0 #e151ab;
  }
  50% {
    box-shadow: 9984px 0 0 0 #e151ab, 9999px -10px 0 0 #e151ab,
      10014px 0 0 0 #e151ab;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #e151ab, 9999px 0 0 0 #e151ab,
      10014px 0 0 0 #e151ab;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #e151ab, 9999px 0 0 0 #e151ab,
      10014px -10px 0 0 #e151ab;
  }
  100% {
    box-shadow: 9984px 0 0 0 #e151ab, 9999px 0 0 0 #e151ab,
      10014px 0 0 0 #e151ab;
  }
}

.css-1mxkqpq {
  color: #0085ff !important;
}

iframe html button {
  color: #0085ff;
}

/* YOUTUBE */
.yt-lite {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  border-radius: 10px;
  cursor: pointer;
}

/* gradient */
.yt-lite::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.507);
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==); */
  background-position: top;
  background-repeat: repeat-x;
  height: 100%;
  padding-bottom: 50px;
  border-radius: 10px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
/* responsive iframe with a 16:9 aspect ratio
  thanks https://css-tricks.com/responsive-iframes/
*/
.yt-lite::after {
  content: "";
  display: block;
  padding-bottom: calc(100% / (16 / 9));
}
.yt-lite > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
}

/* play button */
.yt-lite > .lty-playbtn {
  width: 100px;
  height: 100px;
  /* background-color: #212121; */
  z-index: 1;
  opacity: 0.8;
  border-radius: 14%; /* TODO: Consider replacing this with YT's actual svg. Eh. */
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.yt-lite:hover > .lty-playbtn {
  /* background-color: #f00; */
  opacity: 1;
}
/* play button triangle */
.yt-lite > .lty-playbtn:before {
  content: "";
  border-style: solid;
  border-width: 42px 0 42px 80px;
  border-color: transparent transparent transparent #fff;
}

.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/* Post-click styles */
.yt-lite.lyt-activated {
  cursor: unset;
  opacity: 1 !important;
}
.yt-lite.lyt-activated::before,
.yt-lite.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

@media screen and (max-width: 760px) {
  /* play button triangle */
  .yt-lite > .lty-playbtn:before {
    content: "";
    border-style: solid;
    border-width: 32px 0 32px 60px;
    border-color: transparent transparent transparent #fff;
  }
  .body2 {
    font-size: 14px;
    line-height: 26px;
  }
  .flex-column-sm {
    flex-direction: column;
  }
  .ptb100 {
    padding: 50px 0;
  }
  .sectionGap {
    padding: 10px;
  }
  /* .slick-dots li.slick-active button:before */
}

@media screen and (max-width: 950px) {
  .slick-dots li.slick-active button:before {
    color: #faa004;
    font-size: 16px;
  }
  .slick-slider .slick-arrow {
    display: none !important;
  }
}
